'use strict';
import angular = require('angular');
import {EFE2Features, UserAccount} from '../../../../data/account.data';
import {
  DuplicateGlobalSettings,
  ELogLevel,
  MapsUsage,
  MiscSettings,
  RestProtocolSettings,
  SmsEagleSettings,
  SmsEagleSignal,
  SSOSettings
} from '../../../../data/admin.data';
import {RolePrivilege} from '../../../../data/privileges.enum';
import {WebSocketServerModeEvent} from '../../../../data/socket.data';
import AccountService from '../../../../services/account.service';
import PrivilegeService from '../../../../services/privilege.service';
import RestService from '../../../../services/rest.service';

require('./admin.settings.component.scss');

export default class AdminSettingsComponent {
  public restrict: string;
  public template: any;
  public scope: any;
  public controller: any;
  public controllerAs: string;
  public bindToController: boolean;

  constructor() {
    this.restrict = 'E';
    this.template = require('./admin.settings.component.html');
    this.scope = {};
    this.controller = AdminSettingsComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }

}

/* @ngInject */
class AdminSettingsComponentController {
  public $scope: angular.IScope;
  public $rootScope: angular.IRootScopeService;
  public logLevels: ELogLevel[];
  public isLoadingMapsUsage: boolean;
  public isChangingLogLevel: boolean;
  public isChangingRevision: boolean;
  public usageOk: boolean;
  public isCompacting: boolean;
  public mapsUsage: MapsUsage;
  public dataService: any;
  public $state: any;
  public $log: angular.ILogService;
  public $translate: any;
  public Notification: any;
  public restService: RestService;
  public listeners: any;
  public account: UserAccount;

  public duplicateSettings: DuplicateGlobalSettings;
  public miscSettings: MiscSettings;
  public smsEagleSettings: SmsEagleSettings;
  public isEagleSettingsOk: boolean = false;
  public isLoadingDuplicate: boolean = false;
  public isLoadingMisc: boolean = false;
  public isSmsEagleSettings: boolean = false;
  public isSMSEagleConnection: boolean = false;
  public smsEagleQueueLength: number = -1;
  public smsEagleSignal: SmsEagleSignal;
  public smsEagleSignalStatus: SmsEagleSignalStatus = SmsEagleSignalStatus.DISABLED;
  public statusSMSEagleConnection: SmsEagleConnectionStatus = SmsEagleConnectionStatus.NO;
  public restProtocolSettings: RestProtocolSettings;
  public isLoadingRestProtocolSettings: boolean = false;
  public isLoadingSSOSettings: boolean = false;
  public errorSaveSSOSettings: boolean = false;
  public ssoSettings: SSOSettings;


  public availableSpeeds = [];
  public mode: AdminSettingsMode = AdminSettingsMode.OVERVIEW;

  public isRestarting: boolean = false;

  private updateSmsEagleInfo: any;

  public hasVehicleTrackingFeature = false;

  constructor($scope: angular.IScope, $rootScope: angular.IRootScopeService, dataService, $state, $log, $translate,
    Notification, restService: RestService, private $uibModal: any,
    private accountService: AccountService,
    public privilegeService: PrivilegeService) {

    for (let i = -10; i <= 10; i++) {
      this.availableSpeeds.push(i);
    }

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.logLevels = [ELogLevel.TRACE, ELogLevel.DEBUG, ELogLevel.INFO, ELogLevel.WARN, ELogLevel.ERROR];
    this.isLoadingMapsUsage = false;
    this.isChangingLogLevel = false;
    this.usageOk = true;
    this.isCompacting = false;
    this.mapsUsage = {
      limit: 0,
      used: 0,
      remaining: 0
    } as MapsUsage;
    this.dataService = dataService;
    this.$state = $state;
    this.$log = $log;
    this.$translate = $translate;
    this.Notification = Notification;
    this.restService = restService;
    this.listeners = [];
    this.initListeners();

    if (dataService.hasAccount()) {
      this.account = dataService.account;
      this.init();
    }

  };

  init() {
    this.loadMapsApiUsage();
    this.loadDuplicateSettings();
    this.loadMiscSettings();
    this.loadSmsEagleSettings();
    this.loadSmsEagleSettingsStatus();
    this.loadSMSEagleQueueLength();
    this.loadSMSEagleSignal()
    this.loadRestProtocolSettings();
    this.loadSSOSettings();
    this.updateSmsEagleInfo = setInterval(() => this.updateSMSEagleInfo(), 5 * 1000);
    this.$scope.$on('$destroy', () => {
      clearInterval(this.updateSmsEagleInfo);
    });
    this.hasVehicleTrackingFeature = this.account.features.includes(EFE2Features.VEHICLE_TRACKING);
  }


  /**
   * Log off all active users by generating a new secure signing key
   */
  logOffAllUsers() {
    this.restService.logOffAllUsers(
      () => {
        this.dataService.logout();
        //Switch scope to login
        this.$state.go('main.login');
      },
      (err) => {
        this.$log.error('Could not log off');
        this.$log.error(err);
      });
  };

  /**
   * Compact storage of database
   */
  compactStorage() {
    this.isCompacting = true;
    this.restService.compactStorage(
      (response) => {
        this.isCompacting = false;

        this.$translate(['ADMIN.COMPACT_SUCCESSFULL_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: response.data.message,
            title: translations['ADMIN.COMPACT_SUCCESSFULL_MESSAGE']
          });
        });

      }, (err) => {
        this.$log.error('Could not compact storage');
        this.$log.error(err);
        this.isCompacting = false;
      });
  };

  changeLogLevel(newLogLevel: ELogLevel) {
    this.isChangingLogLevel = true;
    this.restService.changeLogLevel(newLogLevel,
      (response) => {
        this.isChangingLogLevel = false;
        this.account.server.logLevel.levelStr = response.data.levelStr;
        this.account.server.logLevel.levelInt = response.data.levelInt;
        this.$translate(['ADMIN.LOG_CHANGED_MESSAGE', 'ADMIN.LOG_CHANGED_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.LOG_CHANGED_MESSAGE'],
            title: translations['ADMIN.LOG_CHANGED_TITLE']
          });
        });
      },
      (err) => {
        this.$log.error('Could not change log level');
        this.$log.error(err);
        this.isChangingLogLevel = false;
      });
  }

  // Load maps api usage
  loadMapsApiUsage() {
    this.isLoadingMapsUsage = true;
    this.restService.loadMapsUsage(
      (response) => {
        this.isLoadingMapsUsage = false;
        this.$log.info(response.data);
        this.mapsUsage = response.data;
        if (this.mapsUsage.remaining > 0) {
          this.usageOk = true;
        } else {
          this.usageOk = false;
        }
      },
      (err) => {
        this.isLoadingMapsUsage = false;
        this.$log.error('Could not load maps usage');
        this.$log.error(err);
        this.usageOk = false;
      });
  };

  initListeners() {
    //Wait for new account
    this.listeners.push(
      this.$rootScope.$on('new.account', () => {
        this.account = this.dataService.account;
        this.init();
      })
    );

    this.listeners.push(
      this.$rootScope.$on('server.mode', (event, data: WebSocketServerModeEvent) => {
        if (this.account) {
          this.account.version.mode = data.mode;
          this.$scope.$applyAsync();
        }
      })
    );

    this.listeners.push(this.$rootScope.$on('websocket.connection',
      (event, data) => {
        if(data.isConnected) {
          this.isRestarting = false;
          this.$scope.$apply();
        }
      }));

    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  loadDuplicateSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin))return;
    this.isLoadingDuplicate = true;
    this.restService.loadDuplicateSettings()
      .then(settings => {
        this.duplicateSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingDuplicate = false;
        this.$scope.$applyAsync();
      });
  }

  saveDuplicateSettings() {
    this.isLoadingDuplicate = true;
    this.restService.saveDuplicateSettings(this.duplicateSettings)
      .then(settings => {
        this.duplicateSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingDuplicate = false;
        this.$scope.$applyAsync();
      });
  }

  loadMiscSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin))return;
    this.isLoadingMisc = true;
    this.restService.loadMiscSettings()
      .then(settings => {
        this.miscSettings = settings;
      }).catch(err => {
        this.$log.error(err);
      }).finally(() => {
        this.isLoadingMisc = false;
        this.$scope.$applyAsync();
      });
  }

  saveMiscSettings() {
    this.isLoadingMisc = true;
    this.restService.saveMiscSettings(this.miscSettings)
      .then(settings => {
        this.miscSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoadingMisc = false;
      this.$scope.$applyAsync();
    });
  }

  loadSmsEagleSettingsStatus() {
    if (!this.privilegeService.has(RolePrivilege.Admin_Sms_Eagle)) return;
    this.restService.loadSmsEagleSettingsStatus()
      .then(settings => {
        this.isEagleSettingsOk = settings.configurationSet;
      }).catch(err => {
      this.isEagleSettingsOk = false;
      this.$log.error(err);
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  loadSmsEagleSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin_Sms_Eagle)) return;
    this.isSmsEagleSettings = true;
    this.restService.loadSmsEagleSettings()
      .then(settings => {
        this.smsEagleSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isSmsEagleSettings = false;
      this.$scope.$applyAsync();
    });
  }

  saveSmsEagleSettings() {
    this.isEagleSettingsOk = false;
    this.isSmsEagleSettings = true;
    this.restService.saveSmsEagleSettings(this.smsEagleSettings)
      .then(settings => {
        this.smsEagleSettings = settings;
        clearInterval(this.updateSmsEagleInfo);
        this.updateSmsEagleInfo = setInterval(() => this.updateSMSEagleInfo(), 5 * 1000);
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isSmsEagleSettings = false;
      this.statusSMSEagleConnection = SmsEagleConnectionStatus.NO;
      this.$scope.$applyAsync();
    });
  }

  loadRestProtocolSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin_Rest_Protocol_Settings)) return;
    this.isLoadingRestProtocolSettings = true;
    this.isSmsEagleSettings = true;
    this.restService.loadRestProtocolSettings()
      .then(settings => {
        this.restProtocolSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoadingRestProtocolSettings = false;
      this.$scope.$applyAsync();
    });
  }

  saveRestProtocolSettings() {
    this.isLoadingRestProtocolSettings = true;
    this.restService.saveRestProtocolSettings(this.restProtocolSettings)
      .then(settings => {
        this.restProtocolSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoadingRestProtocolSettings = false;
      this.$scope.$applyAsync();
    });
  }

  loadSSOSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin_SSO_Settings)) return;
    this.isLoadingSSOSettings = true;
    this.restService.loadSSOSettings()
      .then(settings => {
        this.ssoSettings = settings;
      }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.isLoadingSSOSettings = false;
      this.$scope.$applyAsync();
    });
  }

  saveSSOSettings() {
    if (!this.privilegeService.has(RolePrivilege.Admin_SSO_Settings)) return;
    this.isLoadingSSOSettings = true;
    this.restService.saveSSOSettings(this.ssoSettings)
      .then(settings => {
        this.ssoSettings = settings;
        this.errorSaveSSOSettings = false;
      }).catch(err => {
      this.$log.error(err);
      this.errorSaveSSOSettings = true;
    }).finally(() => {
      this.isLoadingSSOSettings = false;
      this.$scope.$applyAsync();
    });
  }


  checkSMSEagleConnection() {
    if (!this.isEagleSettingsOk || !this.privilegeService.has(RolePrivilege.Admin)) return;
    this.statusSMSEagleConnection = SmsEagleConnectionStatus.IN_PROGRESS;
    this.restService.checkSMSEagleConnection()
      .then(status => {
        this.isSMSEagleConnection = status;
      }).catch(err => {
      this.isSMSEagleConnection = false;
      this.$log.error(err);
    }).finally(() => {
      this.statusSMSEagleConnection = SmsEagleConnectionStatus.DONE;
      this.$scope.$applyAsync();
    });
  }

  loadSMSEagleQueueLength() {
    if (!this.isEagleSettingsOk || !this.privilegeService.has(RolePrivilege.Admin)) return;
    this.restService.loadSMSEagleQueueLength()
      .then(length => {
        this.smsEagleQueueLength = length;
      }).catch(err => {
        this.smsEagleQueueLength = -1;
        // Dont try it again if there is an error
      clearInterval(this.updateSmsEagleInfo);
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  loadSMSEagleSignal() {
    if (!this.isEagleSettingsOk || !this.privilegeService.has(RolePrivilege.Admin)) return;
    this.restService.loadSMSEagleSignal()
      .then(signal => {
        this.smsEagleSignal = signal;
        if(this.smsEagleSignal.current==-1)
          this.smsEagleSignalStatus=SmsEagleSignalStatus.DISABLED;
        if(this.smsEagleSignal.current<this.smsEagleSignal.min)
          this.smsEagleSignalStatus = SmsEagleSignalStatus.SIGNAL_LESS;
        else this.smsEagleSignalStatus = SmsEagleSignalStatus.SIGNAL_OK;
      }).catch(err => {
      this.smsEagleSignalStatus = SmsEagleSignalStatus.DISABLED;
      // Dont try it again if there is an error
      clearInterval(this.updateSmsEagleInfo);
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  updateSMSEagleInfo() {
    this.loadSmsEagleSettingsStatus();
    this.loadSMSEagleQueueLength();
    this.loadSMSEagleSignal()
  }

  restart() {
    let modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/confirm.restart.modal/confirm.restart.modal.html'),
      controller: 'ConfirmRestartModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {}
    })

    modalInstance.result.then((confirmed: boolean) => {
      if(confirmed) {
        this.isRestarting = true;
        this.$scope.$applyAsync();
        this.executeRestart();
      }
    })
  }

  executeRestart() {
    this.restService.restartFE2()
      .then(() => {
        this.$translate(['ADMIN.RESTART.NOTIFICATION_TITLE', 'ADMIN.RESTART.NOTIFICATION_MESSAGE']).then((translations) => {
          this.Notification.success({
            message: translations['ADMIN.RESTART.NOTIFICATION_MESSAGE'],
            title: translations['ADMIN.RESTART.NOTIFICATION_TITLE']
          });
        })
      })
      .catch(err => {
        this.$translate(['COMMON.UNKNOWN_ERROR', 'COMMON.ERROR_TITLE']).then((err) => {
          this.Notification.error({
            message: err['COMMON.UNKNOWN_ERROR'],
            title: err['COMMON.ERROR_TITLE']
          });
        });
      })
  }

}


enum AdminSettingsMode {
  OVERVIEW = 'OVERVIEW',
  PRIVACY = 'PRIVACY',
  LICENCE = 'LICENCE',
  ACTIONS = 'ACTIONS',
  DUPLICATE = 'DUPLICATE',
  MASSALERT = 'MASSALERT',
  EXPRESSALERT = 'EXPRESSALERT',
  VOICES = 'VOICES',
  MISC = 'MISC',
  KEYWORDS = 'KEYWORDS',
  WEBSERVER = 'WEBSERVER',
  MASTERSLAVE = 'MASTERSLAVE',
  MAILACCOUNT = 'MAILACCOUNT',
  OUTPUTPLUGINS = 'OUTPUTPLUGINS',
  REPLACEMENTS = 'REPLACEMENTS',
  LOGO = 'LOGO',
  OUTAGE = 'OUTAGE',
  FEEDBACK_GROUPS = 'FEEDBACK_GROUPS',
  SPLITTER = 'SPLITTER',
  ADDRESS = 'ADDRESS',
  COMING_HOME = 'COMING_HOME',
  GEOTRACKING = 'GEOTRACKING',
  SMS_EAGLE = 'SMS_EAGLE',
  REST_PROTOCOL = 'REST_PROTOCOL',
  SSO_SETTINGS = 'SSO_SETTINGS',
  STATUS = 'STATUS'
}

enum SmsEagleConnectionStatus {
  NO='NO',
  IN_PROGRESS='IN_PROGRESS',
  DONE='DONE'
}
enum SmsEagleSignalStatus {
  DISABLED='DISABLED',
  SIGNAL_LESS='SIGNAL_LESS',
  SIGNAL_OK='SIGNAL_OK'
}
