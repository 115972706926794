import angular = require("angular");
import {
  EAPagerFieldMode,
  EDataBasePersonType,
  OrganisationPaginatedResponse,
  PersonNewFieldStructure,
  PersonSimple,
  ProvisionField,
  SharePersonRequest,
  UserSearchResponse
} from './../../../../data/person.data';
import {IIntervalService, ILocationService, ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {EAvailabilityState} from "../../../../data/availability.data";
import {AutomaticProvisioningAppliedMode, ProvisioningSimple} from "../../../../data/provisioning.data";
import RestService from '../../../../services/rest.service';
import {SortParams} from '../../../views/addressbook.view.component/addressbook.view.component';
import {RoleSimpleSelection} from '../../../../data/role.data';
import PrivilegeService from "../../../../services/privilege.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import {UserAdminContext} from "../../../../data/admin.data";
import AdminService from "../../../../services/admin.service";

'use strict';

require('./edit.person.modal.css');

/* @ngInject */
export default class EditPersonModalController {
  public $scope: IScope;
  public $rootScope: IRootScopeService;
  public $interval: IIntervalService;
  public $location: ILocationService;
  public $translate: any;
  public $timeout: angular.ITimeoutService;
  public $log: ILogService;
  public $uibModal: any;
  public $uibModalInstance: any;
  public restService: RestService;
  public dataService: any;
  public helperService: any;
  public Notification: any;
  public person: PersonNewFieldStructure;
  public personId: string;
  public tab: number = 0;
  public isSendingTestAlarm: boolean = false;
  public isProvisioning: boolean = false;
  public isProvisioningLoading: boolean = false;
  public isLoadingRoles = false;
  public selectedProvisionProfile: ProvisioningSimple;
  public testAlarmProgress: any;
  public currentaPagerProValue: string;
  public aPagerProValueHasChanged: boolean = false;
  public onlineservice: any;
  public provisionings: ProvisioningSimple[];
  public responseEntry: any;
  public mapping: any;
  public isLoading: boolean = false;
  public isSearching = false;
  public linkToAccessPersonPage: string;
  public hasMailServiceEnabled: boolean = false;
  public mode = PersonModes.DETAILS;
  public listeners = [];
  public account: UserAccount;
  public password: string = '';
  // array of fields in a person entry-> order as defined
  public lstOfFields: string[] = ['aPagerPro', 'email', 'mobil', 'ISSI'];
  public privileges = [];
  public isValidAPagerProValue: boolean;
  public aPagerMode: typeof EAPagerFieldMode = EAPagerFieldMode;
  public provisionFields:typeof ProvisionField= ProvisionField;

  public sharedUsers: UserSearchResponse[] = [];
  public shareIsModified: boolean;
  public personIsShared: boolean;
  public allUsers: OrganisationPaginatedResponse;
  public params: SortParams;
  public openedAsAdmin = false;
  public loginAllowed = false;

  // aPager mode handling variables
  public isGeneratingToken:boolean= false;
  public isTokenGenerated:boolean= false;
  // check validity of each field if necessary
  public isEMailFieldValid: boolean = true;
  public isMobilFieldValid: boolean = true;
  // flag if person is new
  public isNew= false;
  // handles if a provisioning profile can be set, not allowed during migration process
  public isMigrationProcess:boolean= false;
  // buffer the old aPager Value from legacy, allowes resetting the aPager value if someone switches around and determines if it is a migration process
  public aPagerSwitchBuffer:string;

  public roleSelection: RoleSimpleSelection[] = [];
  public userRoleChanged = false;
  public priv:PrivilegeService;
  public hasPersEdit:boolean= false;
  public hasAlarmGroup:boolean= false;
  public hasPersShare:boolean= false;
  public hasProv:boolean= false;
  public hasProvSend: boolean = false;
  public hasProvAssign: boolean= false;
  public hasRoles:boolean= false;
  public hasAddrOnlineS: boolean = false;
  public hasAdminPersonShare: boolean = false;
  public hasGrAndFuncEdit: boolean = false;
  public hasRolesAssign:boolean= false;
  public hasAvailEdit: boolean = false;
  public hasAdmin: boolean = false;
  public isOwnEntry: boolean = false;
  public userHasNoActiveRole: boolean = false;

  public usersForMove: UserAdminContext[] = [];

  constructor($scope: IScope, $rootScope: IRootScopeService, $timeout: angular.ITimeoutService, $interval: IIntervalService, $location: ILocationService, $translate, $log: ILogService, $uibModal, $uibModalInstance, restService, dataService, helperService, privilegeService: PrivilegeService, Notification, person: PersonSimple,
    private adminService: AdminService,
    openedAsAdmin?: boolean, isNew?:boolean) {
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$interval = $interval;
    this.$location = $location;
    this.$translate = $translate;
    this.$timeout = $timeout;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.restService = restService;
    this.dataService = dataService;
    this.helperService = helperService;
    this.Notification = Notification;
    this.openedAsAdmin = openedAsAdmin;
    this.isNew= isNew;
    this.selectedProvisionProfile = undefined;
    this.testAlarmProgress = {
      width: '0%'
    };
    this.priv = privilegeService;

    this.isLoading = true;
    this.personId = person.personID;

    this.loadPerson();
  }

  private loadAllUsersForMove() {
    this.adminService.getAllUsers().then(users => {
      this.usersForMove = users.filter(user => user.name !== this.person.parent);
    }).catch(err => {
      this.$log.error(err);
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  getToDisplayMode() {
    if (!this.person || !this.person.apagerSettings) return EAPagerFieldMode.NO_APAGER;
    return this.person.apagerSettings.apagerFieldMode;
  }

  sharePossible(user: UserSearchResponse) {
    var sharePossible = (this.hasAdminPersonShare || this.hasPersShare) && !this.userIsSelected(user);
    return sharePossible;
  }

  loadPerson() {
    this.restService.loadPersonWithNewFieldStructure(this.personId).then(result => {
      this.person = result;
      this.loginAllowed = this.person.loginAllowed;
      if (this.person.apagerSettings) {
        this.currentaPagerProValue = this.person.apagerSettings.apagerProValue;
      }
      this.init();
      this.personIsShared = this.person.shared || this.person.dataBasePersonType === EDataBasePersonType.SHARED;
      this.isOwnEntry = this.person.personID == this.account.personID;
      this.isTokenGenerated = this.hasApagerPro() && this.person.apagerSettings.apagerFieldMode === EAPagerFieldMode.TOKEN;
      if (this.isTokenGenerated) {
        this.person.apagerSettings.apagerProValue = this.person.apagerSettings.apagerProValue.toUpperCase();
      }

      if (this.openedAsAdmin) {
        this.loadAllUsersForMove();
      }

    }).finally(() => {
      this.isLoading = false;
      this.userHasNoActiveRole = !this.person?.roles || this.person.roles.length === 0 || this.person.roles.filter(role => role.active).length === 0;
      this.$scope.$applyAsync();
    });
  }

  switchAPagerMode(mode:EAPagerFieldMode) {
    // only perform action if other mode was selected than saved in person (no deleting value on click on same option)
      if (this.person.apagerSettings.apagerFieldMode === EAPagerFieldMode.LEGACY){
        this.aPagerSwitchBuffer = this.person.apagerSettings.apagerProValue;
      }
       this.person.apagerSettings.apagerFieldMode = mode;
       switch (mode) {
        case EAPagerFieldMode.TOKEN: {
          // no switching allowed once saved
          this.person.apagerSettings.apagerProValue = undefined;
          // set as default
          this.person.apagerSettings.keyOfProvisioningField = ProvisionField.EMAIL;
          this.validateAPagerPro();
          this.checkIfMigrationProcess();
          break;
        }
        case EAPagerFieldMode.LEGACY: {
          this.person.apagerSettings.apagerProValue = this.aPagerSwitchBuffer;
          this.person.apagerSettings.keyOfProvisioningField = undefined;
          this.isMigrationProcess = false;
          this.validateAPagerPro();
          break;
      }
      case EAPagerFieldMode.NO_APAGER:{
          this.person.apagerSettings.apagerProValue = undefined;
          // do this if someone is switching around in modal, before saving
          this.person.apagerSettings.keyOfProvisioningField = undefined;
          this.isEMailFieldValid= this.validateEMailFormat(this.person.data.email);
          this.isMobilFieldValid= this.validatePhoneFormat(this.person.data.mobil);
           this.isMigrationProcess = false;
           break;
        }
      }
  }

  private checkIfMigrationProcess() {
    // don't allow selecting provisioning profile in frontend on migration, to lower the complexity of resending the provisionings
    if (!this.isNew) {
      // check on prov profile as well, and SwitchBuffer cause only migration process has a defined buffer value and prov profile
      this.isMigrationProcess = true;
    }
  }

  generateAPagerToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (!this.hasPersEdit) {
        resolve(false);
        return;
      }
      this.isGeneratingToken = true;
      this.restService.generateAPagerToken(this.person).then((response) => {
        this.person.apagerSettings.apagerProValue = response.token.toUpperCase();
        this.person.apagerSettings.tokenGenerated = true;
        this.isTokenGenerated = true;
        this.isValidAPagerProValue = true;
        resolve(true);
      }).catch(err => {
        reject(err);
      }).finally(() => {
        this.isGeneratingToken = false;
      });
    });

  }

  addToPerson(user: UserSearchResponse) {
    this.shareIsModified = true;
    this.sharedUsers.push(user);
  }

  removeFromPerson(user: UserSearchResponse) {
    const foundElement = this.sharedUsers.filter(entry => entry.userId === user.userId);

    if (foundElement.length > 0) {
      this.shareIsModified = true;
      this.sharedUsers.splice(this.sharedUsers.indexOf(foundElement[0]), 1);
      this.pageChanged();
    }
  }

  pageChanged() {
    if (!(this.account.admin || this.account.personShareFromUser)) {
      // user is not admin and has no right to use share function
      return;
    }
    this.isSearching = true;
    this.restService.getAllUsersPaginated(this.params.currentPage === 0 ? 0 : this.params.currentPage - 1, this.params.pageSize, this.params.searchFilter).then(users => {
      this.allUsers = users;
    }).catch(error => {
      this.$translate(['COMMON.FORBIDDEN']).then((translations) => {
        this.Notification.error({
          message: translations['COMMON.FORBIDDEN'],
          title: error.data.message
        });
      });
    }).finally(() => {
      this.isSearching = false;
      this.updateUI();
      this.$scope.$applyAsync();
      this.$timeout(function () {
        let doc = document.getElementById('edit-person-user-search');
        if (doc) {
          doc.focus();
        }
      });
    });
  }

  userIsSelected(user: UserSearchResponse) {
    return this.sharedUsers.filter(entry => entry.userId === user.userId).length > 0;
  }

  isParentUser(user: UserSearchResponse) {
    return this.person.parent === user.userName;
  }

  updateUI() {
    if (angular.isUndefined(this.allUsers)) {
      return;
    }
    this.params.totalElements = this.allUsers.totalElements;
    this.params.totalPages = this.allUsers.totalPages;
    this.isLoading = false;
  }

  /**
   * Trigger a search
   */
  search() {
    this.params.currentPage = 0;
    this.pageChanged();
  }

  init() {
    // Set color
    this.person.color = this.helperService.getColorForAvailability(this.person.availability);

    //Init
    this.account = this.dataService.getAccount() as UserAccount;
    this.hasPersEdit = this.priv.has(RolePrivilege.Addressbook_Persons_Edit);
    this.hasAlarmGroup = this.priv.has(RolePrivilege.Addressbook_AlarmGroup);
    this.hasProv = this.priv.has(RolePrivilege.Addressbook_Provisioning);
    this.hasProvSend = this.priv.has(RolePrivilege.Addressbook_Provisioning_Send)
    this.hasProvAssign = this.priv.has(RolePrivilege.Addressbook_Provisioning_Assign);
    this.hasPersShare = this.priv.has(RolePrivilege.Addressbook_Persons_Share);
    this.hasRoles = this.priv.has(RolePrivilege.User_Roles);
    this.hasAddrOnlineS = this.priv.has(RolePrivilege.Addressbook_OnlineService);
    this.hasRolesAssign = this.priv.has(RolePrivilege.User_Roles_Assign);
    this.hasGrAndFuncEdit= this.priv.has(RolePrivilege.Addressbook_Functions_Groups_Edit)
    this.hasAvailEdit = this.priv.has(RolePrivilege.Addressbook_Availability_Edit);
    this.hasAdmin = this.priv.has(RolePrivilege.Admin);
    this.hasAdminPersonShare = this.priv.has(RolePrivilege.Admin_PersonShare);
    //Create link
    this.createAccessLink();
    this.hasMailServiceEnabled = this.account.hasMailServiceEnabled;

    if (this.account.hasOnlineService) {
      if (this.hasAddrOnlineS) {
        this.restService.loadAvailabilityForAddressbook(false).then((response) => {
          this.onlineservice = response;
      });
    }
    }
    // validate aPager Pro field on initial loading
    this.validateAPagerPro();
    // Load all provisioning profiles
    if (this.hasProv) {
      this.isProvisioningLoading = true;
      this.dataService.getProvisionings(false, (data: ProvisioningSimple[]) => {
        this.isProvisioningLoading = false;
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.provisionings = data;

        if (this.person.hasProvisioningProfile) {
          const currentId = this.person.provisioningProfile.id;
          const foundProfile = this.provisionings.find(prov => prov.id === currentId);
          if (foundProfile !== undefined) {
            this.selectedProvisionProfile = foundProfile;
          }
        }

        if (data.length > 0 && !this.selectedProvisionProfile) {
          // Select first profile as default
          this.selectedProvisionProfile = data[0];
        }
      }, (err) => {
        this.provisionings = [];
        this.isProvisioningLoading = false;
        this.$log.error(err);
      });
    }

    this.restService.getUsersForPerson(this.person.personID).then((users: UserSearchResponse[]) => {
      this.sharedUsers = users;
    }).catch(err => {
      this.$log.error('Could not load users for person', err);
      this.$translate(['COMMON.UNKNOWN_ERROR', 'USERS.COULD_NOT_LOAD_USER_SHARES']).then((translations) => {
        this.Notification.error({
          message: translations['USERS.COULD_NOT_LOAD_USER_SHARES'],
          title: translations['COMMON.UNKNOWN_ERROR']
        });
      });
    }).finally(() => {
      this.$scope.$applyAsync();
    });
    if (this.hasRoles) {
      this.loadRoles();
    }

    this.params = {
      searchFilter: '', // set the default search/filter term
      currentPage: 0,
      totalElements: 0,
      pageSize: 20
    } as SortParams;


    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has an unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  validateAPagerPro() {
    if (this.person.apagerSettings.apagerFieldMode === EAPagerFieldMode.NO_APAGER) {
      return;
    }

    if (this.person.apagerSettings.apagerFieldMode=== EAPagerFieldMode.LEGACY) {
      this.validateLegacyAPager(this.person.apagerSettings.apagerProValue);
    }
    if (this.person.apagerSettings.apagerFieldMode=== EAPagerFieldMode.TOKEN){
      this.validateToken(this.person.apagerSettings.apagerProValue);
    }

    switch (this.person.apagerSettings.apagerFieldMode) {
      case EAPagerFieldMode.TOKEN:
        // Validate email and mobil
        switch (this.person.apagerSettings.keyOfProvisioningField) {
          case ProvisionField.EMAIL:
            if (!this.person.data.email) {
              this.isEMailFieldValid = false;
            } else {
              this.isEMailFieldValid = this.validateEMailFormat(this.person.data.email);
            }
            break;
          case ProvisionField.MOBIL:
            if (!this.person.data.mobil) {
              this.isMobilFieldValid = false;
            } else {
              this.isMobilFieldValid = this.validatePhoneFormat(this.person.data.mobil);

            }
            break;
        }
        break;
      default:
        if (this.person.data.email) {
          this.isEMailFieldValid = this.validateEMailFormat(this.person.data.email);
        } else {
          this.isEMailFieldValid = true; // Empty is alway valid
        }
        if (this.person.data.mobil) {
          this.isMobilFieldValid = this.validatePhoneFormat(this.person.data.mobil);
        } else {
          this.isMobilFieldValid = true;
        }
        break;
    }

    this.$scope.$applyAsync();
  }

  selectProvisionField(provisionField:ProvisionField){
    this.person.apagerSettings.keyOfProvisioningField= provisionField;
    this.validateAPagerPro();
  }

  getPlaceholder(): string {

    if (!this.person) {
      return '_PLACEHOLDER_LEGACY';
    }

    if (!this.person.apagerSettings) {
      return '_PLACEHOLDER_LEGACY';
    }

    if (!this.person.apagerSettings.apagerFieldMode) {
      return '_PLACEHOLDER_LEGACY';
    }

    if (this.person.apagerSettings.apagerFieldMode=== EAPagerFieldMode.LEGACY) {
        return '_PLACEHOLDER_LEGACY';
    }
    if (this.person.apagerSettings.apagerFieldMode=== EAPagerFieldMode.TOKEN){
      return '_PLACEHOLDER_TOKEN';
    }
  }

  private validateLegacyAPager(value: string) {
    // do not show warning on empty field
    if (angular.isUndefined(value) || value === '') {
      this.isValidAPagerProValue = true;
      return;
    }
    // undo if valid was true
    this.isValidAPagerProValue = false;
    this.valuesChanged();
    // check on E-Mail first
    this.isValidAPagerProValue = this.validateEMailFormat(value);
    if (this.isValidAPagerProValue) {
      return;
    }
    // validate on phone number if previous validation failed
    this.isValidAPagerProValue = this.validatePhoneFormat(value);
  }

  validateToken(value:string){
    //this.valuesChanged();
    if (angular.isUndefined(value) || !value){
      this.isValidAPagerProValue = false;
      return;
    }
    if (value.length!== 8){
      this.isValidAPagerProValue = false;
      return;
    }
    // regex matching parallel to backend structure
    if (value.match(/^[a-zA-Z0-9.]+$/)){
      this.isValidAPagerProValue = true;
      return;
    }
    this.isValidAPagerProValue = false;
  }
  validateAllowedIPRange():boolean{
    if(!this.person.allowedIPRange){
      return true;
    }
    const regexp = new RegExp(/^(?:(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9]|\*)(\.(?!$)|$)){4}$/);

    if (this.person.allowedIPRange.match(regexp)) {
      return true;
    }
    return false;
  }
  validateEMailFormat(value: string):boolean {
    if (angular.isUndefined(value)||!value) {
      if (this.person.apagerSettings.keyOfProvisioningField && this.person.apagerSettings.keyOfProvisioningField === ProvisionField.EMAIL) {
        return false;
      }
      // don't display error on empty optional fields, in case of other modes
      return true;
    }
    if (value.includes("@")) {
      // Regex from https://stackoverflow.com/a/46370978
      const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

      if (value.match(regexp)) {
        return true;
      }
      return false;
    }
  }

  validatePhoneFormat(value: string): boolean {
    if (angular.isUndefined(value)||!value){
      if (this.person.apagerSettings.keyOfProvisioningField && this.person.apagerSettings.keyOfProvisioningField === ProvisionField.MOBIL) {
        return false;
      }
      // don't display error on empty optional fields, in case of other modes
      return true;
    }
    if (!value.startsWith("00")) {
      return false;
    }
    // contains only digit values and starts with double zeros
    if (value.match("^00[0-9]+$")) {
      return true;
    }

    return false;
  }

  valuesChanged() {
    if (!this.person) return false;
    if (!this.person.apagerSettings)return false;
    this.aPagerProValueHasChanged = this.currentaPagerProValue !== this.person.apagerSettings.apagerProValue;
  }

  /**
   * Returns true if the person has aPager PRO information
   */
  hasApagerPro() {
    if (!this.person) return false;
    if (!this.person.apagerSettings) return false;
    return angular.isDefined(this.person.apagerSettings.apagerProValue) &&  this.person.apagerSettings.apagerProValue;
  }

  isApagerProAFieldMobilePhone() {
    if (this.person.apagerSettings.apagerFieldMode=== this.aPagerMode.TOKEN){
      return this.person.apagerSettings.keyOfProvisioningField === ProvisionField.MOBIL;
    }
    // This is a simple check if the aPager PRO field contains a @ sign. If not, the field is treated like a mobile phone number
    var aPagerField = this.person.apagerSettings.apagerProValue;
    if (angular.isDefined(aPagerField)) {
      return !aPagerField.includes('@');
    }
    return false;
  }

  close() {
    this.$uibModalInstance.close();
  }

  switchStatusAvailability() {
    this.person.connectStatusWithAvailability = !this.person.connectStatusWithAvailability;
  }

  isSelected(field:string){
    return field === this.person.apagerSettings.keyOfProvisioningField;
  }

  getFieldName(provisionFied:ProvisionField){
    if (angular.isUndefined(provisionFied) || !provisionFied){
      this.person.apagerSettings.keyOfProvisioningField = ProvisionField.EMAIL;
      return 'email';
    }
    switch (provisionFied){
      case ProvisionField.EMAIL:
        return 'email';
      case ProvisionField.MOBIL:
        return 'mobil';
    }
  }

  getFieldIcon(field: string) {
    if (field === "aPagerPro" || field === 'ISSI') {
      return '';
    } else if (field === 'mobil') {
      return 'fal fa-mobile';
    } else if (field === 'email') {
      return 'fal fa-envelope';
    }
    return '';
  }

  sendAlarm(isInfoAlarm: boolean) {
    this.isSendingTestAlarm = true;
    this.testAlarmProgress.width = '0%';
    // timer for 15 seconds
    this.$interval((iteration: number) => {
      this.testAlarmProgress.width = (iteration / 30 * 100) + '%';
    }, 500, 15 * 2);

    this.restService.sendTestAlarm(this.person, isInfoAlarm,
      (response) => {
        this.responseEntry = response.data.entries[0];
        this.$log.debug(this.responseEntry);
        this.mapping = {};
        this.mapping[this.person.apagerSettings.apagerProValue] = this.person;
        this.isSendingTestAlarm = false;
        this.testAlarmProgress.width = '0%';
        this.$translate(['PERSON.TEST_APAGER_SUCCESS', 'PERSON.TEST_APAGER_TITLE']).then((translations) => {
          this.Notification.success({
            message: translations['PERSON.TEST_APAGER_SUCCESS'],
            title: translations['PERSON.TEST_APAGER_TITLE']
          });
        });

      },
      (response) => {
        this.testAlarmProgress.width = '0%';
        //Error occured
        this.isSendingTestAlarm = false;
        this.$log.error(response);
      });
  }

  save() {
    this.isLoading = true;
    //Remove property isExpanded

    //in this case person did not have a login before and we generate a new password
    if (this.loginAllowed && !this.person.loginAllowed) {
      this.generatePassword(false);
    }
    this.person.loginAllowed = this.loginAllowed;

    if(!this.person.apagerSettings.apagerProValue) {
        this.person.sendPasswordViaApager = false;
    }
    if(!this.person.data.email || !this.hasMailServiceEnabled) {
        this.person.sendPasswordViaMail = false;
    }

    if (this.shareIsModified) {
      var userIds: string[] = [];
      this.sharedUsers.forEach(user => {
        userIds.push(user.userId);
      })
      this.restService.updateSharedPersonForUser({ personId: this.person.personID, userIds } as SharePersonRequest).finally(() => {
        this.shareIsModified = false;
        this.savePerson();
      });
    } else {
      if (this.person.apagerSettings.apagerFieldMode === EAPagerFieldMode.TOKEN) {
        if (!this.hasApagerPro()) {
          this.generateAPagerToken().then(() => {
            this.savePerson();
          });
          return;
        }
      }
      this.savePerson();
    }
  }

  isAPagerTokenDataValid():boolean{
    if (this.person.apagerSettings.apagerFieldMode === EAPagerFieldMode.TOKEN) {
      // check selected receiver address is set don't allow saving person if field is invalid or empty
      switch (this.person.apagerSettings.keyOfProvisioningField){
        case ProvisionField.MOBIL:
          return this.isMobilFieldValid;
        case ProvisionField.EMAIL:
          return this.isEMailFieldValid;
      }
    }
    return true;
  }


  private savePerson(): Promise<void> {
    if(!this.validateAllowedIPRange()) {
      this.person.allowedIPRange ="";
    }
    return new Promise<void>((resolve) => {
      this.restService.savePersonNewFieldStructure(this.person).then(
        (response) => {
          this.isMigrationProcess = false;// Reset migration
          this.person.apagerPersonData = response.apagerPersonData;
          this.person.data = response.data;
          this.aPagerProValueHasChanged = false;
          this.person.username = response.username;
          this.person.loginAllowed = response.loginAllowed;
          this.person.allowedIPRange = response.allowedIPRange;
          if (this.person.tmpPassword) {
            this.password = this.person.tmpPassword;
          }
          this.$rootScope.$broadcast("person.saved", this.person);
          this.$rootScope.$broadcast('update.person', this.person);

          if (this.userRoleChanged) {

            //role is either being removed (selected role is undefined) or a role is being added
            //if we do not check this, a error is thrown because backend cant find the role
            this.restService.assignRole(this.person.personID, this.roleSelection).finally(() => {
              this.isLoading = false;
              this.userRoleChanged = false;
              this.loadPerson();
              this.$scope.$applyAsync();
            });
          } else {
            this.isLoading = false;
            this.$scope.$applyAsync();
          }
        }).finally(() => {
          this.isLoading = false;
          this.$scope.$applyAsync();
          resolve();
        });
    });

  }

  /**
   * Provision the person with the given provisioning profile
   */
  provision() {
    if (angular.isUndefined(this.selectedProvisionProfile)) {
      return;
    }
    this.isProvisioning = true;
    this.restService.provisionSinglePerson(this.selectedProvisionProfile.id, this.person.personID, false).then((result) => {

      switch (result.appliedMode) {
        case AutomaticProvisioningAppliedMode.VIA_PUSH:
        case AutomaticProvisioningAppliedMode.FALLBACK_VIA_EMAIL:
        case AutomaticProvisioningAppliedMode.FORCE_MODE:
          this.$translate('AutomaticProvisioningAppliedMode.' + result.appliedMode).then((translation) => {
            this.Notification.success({
              message: translation,
              title: this.person.displayName,
            });
          });

          break;
        case AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS:
          this.$translate('AutomaticProvisioningAppliedMode.DID_NOTHING_AS_TYPE_IS_SMS').then((translation) => {
            this.Notification.warning({
              message: translation,
              title: this.person.displayName,
            });
          });
          break;
      }
    }).catch( (errorResponse) => {
        //Error occured
        this.$log.error(errorResponse);
      }
    ).finally(() => {
      this.isProvisioning = false;
    });

  }

  /**
   * Get the CSS class for provisioning version label
   * @returns
   */
  getClassForLabel() {
    if (!this.person) {
      return 'label-primary';
    }

    if (this.person.apagerPersonData.version === this.person.apagerPersonData.installedVersion) {
      return 'label-success';
    } else if (this.person.apagerPersonData.version > this.person.apagerPersonData.installedVersion && this.person.apagerPersonData.installedVersion !== 0) {
      return 'label-danger';
    }
    return 'label-primary';
  }

  /**
   * Check if person has function
   */
  hasPersonFunction(fu: string) {
    return this.person.functions !== undefined && this.person.functions.indexOf(fu) !== -1;
  }

  hasPrivilege(privilege: RolePrivilege) {
    return this.priv.has(privilege);
  };

  /**
   * Add or remove a function from/to a person
   */
  addRemoveFunctionToPerson(fu: string) {
    if (this.person.functions === undefined) {
      this.person.functions = [];
      this.person.functions.push(fu);
      return;
    }
    var existingIndex = this.person.functions.indexOf(fu);
    if (existingIndex !== -1) {
      this.person.functions.splice(existingIndex, 1);
    } else {
      this.person.functions.push(fu);
    }
  };

  /**
   * Check if person has group
   */
  hasPersonGroup(group: string) {
    return this.person.groups !== undefined && this.person.groups.indexOf(group) !== -1;
  }

  /**
   * Add or remove a group from/to a person
   */
  addRemoveGroupToPerson(group: string) {
    if (this.person.groups === undefined) {
      this.person.groups = [];
      this.person.groups.push(group);
      return;
    }
    var existingIndex = this.person.groups.indexOf(group);
    if (existingIndex !== -1) {
      this.person.groups.splice(existingIndex, 1);
    } else {
      this.person.groups.push(group);
    }
  };

  /**
   * Open the functions and groups overview modal
   */
  openFunctionsGroups() {
    this.$uibModal.open({
      template: require('../../../modals/availability/functions.groups.modal/functions.groups.modal.html'),
      controller: 'FunctionsGroupsModalController',
      controllerAs: 'ctrl',
      size: 'lg'
    });
  };


  /**
   * Set the persons availability status
   */
  setAvailability(availability: EAvailabilityState) {
    this.isLoading = true;
    this.restService.setAvailabilityForPerson(this.person, availability, (response) => {
      this.isLoading = false;
      this.$rootScope.$broadcast('update.person', this.person);
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  };

  /**
   * Show the link to access a person via external page
   */
  showLinkToAccessPersonPage() {
    this.linkToAccessPersonPage = this.createAccessLink();
  };

  /**
   Change the persons authentificationID
   */
  resetLinkToAccessPersonPage() {
    if(this.person.shared){
      return;
    }
    this.isLoading = true;
    this.restService.resetLinkToAccessPersonPage(this.person, (response) => {
      this.isLoading = false;
      //Update new ID
      this.person.authentificationID = response.data.authentificationID;
      this.createAccessLink();

      this.$translate(['PERSON.RESET_TITLE', 'PERSON.RESET_MESSAGE']).then((translations) => {
        this.Notification.success({
          message: translations['PERSON.RESET_MESSAGE'],
          title: translations['PERSON.RESET_TITLE']
        });
      });
    }, (response) => {
      //Error occured
      this.isLoading = false;
      this.$log.error(response);
    });
  };

  /**
   * Create external access link
   */
  createAccessLink() {
    if (this.person.shared){
      return ;
    }

    var external = this.account.external as string;

    // Add port
    if (external.indexOf(':') === -1) {
      // a port is not available, we add it if protocol is not https and port != 443
      if (external.startsWith("https")) {
        if (this.$location.port() != 443) {
          // server is https, but por is not 443 -> add ist
          external = external + ':' + this.$location.port();
        } else {
          // server uses https and port is 443 -> dont need the port
        }
      } else {
        external = external + ':' + this.$location.port();
      }
    }

    // Add protocol
    if (!external.startsWith('http')) {
      // Link does not contain http yet
      external = this.$location.protocol() + '://' + external;
    }

    var link = external + '#!/external/persons?id=' + this.person.personID + '&auth=' + this.person.authentificationID + '&username=' + encodeURIComponent(this.account.username);
    this.linkToAccessPersonPage = link;
    return link;
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.linkToAccessPersonPage).then(() => {
      this.$translate(['ADMIN.CLIPBOARD_TITLE']).then((translations) => {
        this.Notification.success({
          title: translations['ADMIN.CLIPBOARD_TITLE']
        });
      });
    });
  }

  humanReadableDateTime(timestamp) {
    return this.helperService.humanReadableDateTime(timestamp);
  }

  /**
   * Add a person to a group or if the person is already added to the group remove the person
   */
  addToAlarmGroup(alarmGroup) {
    var shouldBeDeleted = false;
    var existingIndex = -1;
    for (var i = 0; i < alarmGroup.personIds.length; i++) {
      if (alarmGroup.personIds[i] === this.person.personID) {
        //Already exists
        existingIndex = i;
        shouldBeDeleted = true;
        break;
      }
    }
    if (shouldBeDeleted) {
      this.$log.debug('Delete person from alarmGroup');
      alarmGroup.personIds.splice(existingIndex, 1);
    } else {
      this.$log.debug('Add person to alarmGroup');
      alarmGroup.personIds.push(this.person.personID);
    }
  }


  /**
   * Checks if the person is in the group
   */
  isInAlarmGroup(alarmGroup) {
    if (angular.isUndefined(alarmGroup)) {
      //Invalid values, nothing to do
      this.$log.warn('Invalid values. Group not defined');
      return false;
    }
    for (var i = 0; i < alarmGroup.personIds.length; i++) {
      if (alarmGroup.personIds[i] === this.person.personID) {
        return true;
      }
    }
    return false;
  }

  toggleEmailNotification() {
    if (!this.hasMailServiceEnabled) {
      return;
    }
    if (!this.person.data.email) {
      // Person has no eMail field
      return;
    }
    this.person.notifications.viaEmail = !this.person.notifications.viaEmail
  }

  toggleAPagerNotification() {
    if (!this.person.apagerSettings.apagerProValue) {
      // Person has no aPagerPro field
      return;
    }

    this.person.notifications.viaAPager = !this.person.notifications.viaAPager;
  }

  generatePassword(displayInUI: boolean) {
    this.person.tmpPassword = this.helperService.generatePassword(12);
    if (displayInUI) {
      this.password = this.person.tmpPassword;
    }
  }

  reset2FA() {
    this.isLoading = true;
    this.restService.reset2FaForPerson(this.person.username, (data) => {
      this.person = data.data;
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.$log.error(error);
    })
  }

  /**
   * Load all roles
   */
  loadRoles() {
    this.isLoadingRoles = true;
    this.restService.loadRolesSimple().then(roles => {
      this.roleSelection = [];
      roles.forEach(role => {
        const tmpRoleSelect = role as RoleSimpleSelection
        tmpRoleSelect.selected = this.person.roles.filter(personRole => personRole.id === role.id).length === 1;
        this.roleSelection.push(tmpRoleSelect);
      });

      this.userHasNoActiveRole = this.person.roles.length === 0 || this.person.roles.filter(role => role.active).length === 0

    }).finally(() => {
      this.$scope.$applyAsync();
      this.isLoadingRoles = true;
    });
  }

  changeRoleAssignment(role: RoleSimpleSelection) {

    if (!this.hasPersEdit) {
      return;
    }

    if (!this.hasRolesAssign) {
      return;
    }

    if (this.person.personID === this.account.personID){
      // do not allow assignment of new roles for own entry
      return;
    }
    if (!role) {
      return;
    }

    if (role.selected) {
      this.addRoleToSelectedRoles(role);
    } else {
      this.removeRoleFromSelected(role);
    }

    this.userRoleChanged = true;
  }


  addRoleToSelectedRoles(role: RoleSimpleSelection) {
    if (this.roleSelection.filter(selectedRole => selectedRole.id === role.id).length === 0) {
      this.roleSelection.push(role);
    }
  }

  removeRoleFromSelected(role: RoleSimpleSelection) {
    this.roleSelection.splice(this.roleSelection.indexOf(role), 1);
  }

  toggleSendPasswordViaApager(): void {
    if (!this.person.apagerSettings.apagerProValue) return;
    this.person.sendPasswordViaApager = !this.person.sendPasswordViaApager;

  }
  toggleSendPasswordViaMail(): void {
    if (!this.person.data.email || !this.hasMailServiceEnabled) return;
    this.person.sendPasswordViaMail = !this.person.sendPasswordViaMail;
  }

  public copyPasswordToClipboard() {
    if (this.password) {
      navigator.clipboard.writeText(this.password);
      this.$translate(['PERSON.PERMISSIONS.PASSWORD_COPIED', 'PERSON.PERMISSIONS.PASSWORD_COPIED_MESSAGE']).then((translations) => {
        this.Notification.success({
          message: translations['PERSON.PERMISSIONS.PASSWORD_COPIED_MESSAGE'],
          title: translations['PERSON.PERMISSIONS.PASSWORD_COPIED']
        });
      });
    }
  }

  public async movePerson(user: UserAdminContext) {
    if (user.name === this.person.parent) {
      this.$translate(['PERSON.MOVE.TO_PARENT']).then((translations) => {
        this.Notification.error({
          message: translations['PERSON.MOVE.TO_PARENT']
        });
      });
      return;
    }
    let modalInstance = this.$uibModal.open({
      template: require('../../../modals/admin/confirm.move.modal/confirm.move.modal.html'),
      controller: 'ConfirmMoveModalController',
      controllerAs: 'ctrl',
      size: 'lg',
      backdrop: 'static',
      resolve: {}
    })
    const confirmed = await modalInstance.result;
    if (confirmed) {
      try {
        await this.restService.movePersonToUser(this.personId, user.id);
        this.$uibModalInstance.close();
      } catch (e) {
        const title = await this.$translate("COMMON.ERROR_TITLE");
        this.Notification.error({
          message: e.data.message,
          title
        });
      }
    }
  }

  // used to set a css class for text transformation to uppercase for token
  isNotEmail(identifier: string) {
    return this.helperService.valueIsNotMail(identifier);
  }
}

export enum PersonModes {
  DETAILS = 'DETAILS',
  ACCESS = 'ACCESS',
  OS = 'OS',
  NOTIFICATION = 'NOTIFICATION',
  PERMISSIONS = 'PERMISSIONS',
  SHARE = 'SHARE',
  ALARM_GROUPS = 'ALARM_GROUPS',
  SUBSTITUTES = 'SUBSTITUTES',
  PROPERTIES = 'PROPERTIES',
  BADGES = 'BADGES',
  MOVE = 'MOVE'
}
