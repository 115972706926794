import { IScope } from "angular";
import RestService from "../../../../services/rest.service";
import ErrorService from "../../../../services/error.service";
import { ConnectionResponse, HttpMetrics, MqttStatus } from "../../../../data/admin.data";

require("./admin.connections.component.scss")

export default class AdminConnectionsComponent {
    public restrict: string;
    public template: any;
    public scope: any;
    public controller: any;
    public controllerAs: string;
    public bindToController: boolean;

    constructor() {
      this.restrict = 'E';
      this.template = require('./admin.connections.component.html');
      this.scope = {};
      this.controller = AdminConnectionsController;
      this.controllerAs = 'ctrl';
      this.bindToController = true;
    }
}

class AdminConnectionsController {
    public connections: MqttStatus[] = [];
    public metrics: HttpMetrics[] = [];
    public staticConnections: ConnectionResponse[] = [];
    public isLoadingConnections = false;
    public isLoadingMetrics = false;
    public isLoadingStatic = false;

    constructor(private readonly restService: RestService,
        private readonly $scope: IScope,
        private readonly errorService: ErrorService) {
        this.load();
    }


    public async load() {
        this.isLoadingConnections = true;
        this.isLoadingMetrics = true;
        this.isLoadingStatic = true;

        // Load MQTT connections
        this.restService.getConnections()
            .then(connections => this.connections = connections)
            .catch(err => this.errorService.notifyWithText(err.data.message))
            .finally(() => {
                this.isLoadingConnections = false;
                this.$scope.$applyAsync();
            });

        // Load HTTP connections
        this.restService.getHttpMetrics()
            .then(metrics => this.metrics = metrics)
            .catch(err => this.errorService.notifyWithText(err.data.message))
            .finally(() => {
                this.isLoadingMetrics = false;
                this.$scope.$applyAsync();
            });

        // Load static connections
        this.restService.getStaticConnections()
            .then(staticConnections => this.staticConnections = staticConnections)
            .catch(err => this.errorService.notifyWithText(err.data.message))
            .finally(() => {
                this.isLoadingStatic = false;
                this.$scope.$applyAsync();
            });
    }

}